<template>
  <el-menu
    :default-active="`/${reportType}/${reportID}/${taburl}`"
    class="report-menu"
    mode="horizontal"
    background-color="#FFF"
    active-background-color="#fff"
    active-text-color="#2F73FF"
    style="box-shadow: 0px 0px 8px 0px rgba(139, 151, 182, 0.3);"
  >
    <template class="menutree" v-for="menu in menudata">
      <menutree :itemdata="menu" :key="menu.id" :reportType="reportType" :reportID="reportID" @menuItemClick="menuItemClick"></menutree>
    </template>
  </el-menu>
</template>
<script>
import menutree from "./menutree";
export default {
  components: {
    menutree: menutree
  },
  props: ["menudata", "taburl", "reportType", "reportID"],
  methods:{
    menuItemClick(url){
      this.$emit('menuCilck',url)
    }
  }
};
</script>

<style>
.report-menu {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top:104px;
  z-index: 10;
}
.report-menu > .report-submenu {
  flex: 1;
  text-align: center;
  position: relative;
  border-right: 1px solid #ebeef3;
  border-left: 1px solid #ebeef3;
  border-top: 1px solid #ebeef3;
}
.report-menu > .report-submenu:last-child {
  /* border-right-width: 0; */
}
.report-menu > div:focus {
  outline: none;
}
.el-header > .el-menu > .report-menu {
  display: flex;
  justify-content: space-between;
}
.el-menu-item.is-active,
.el-submenu.is-active .el-submenu__title {
  font-weight: 600;
}
.el-menu-item:hover,
.el-submenu__title:hover {
  background-color: rgba(0, 0, 0, 0) !important;
  color: #409eff !important;
}
.el-menu-item.is-active {
  /* border: 0 !important; */
  color: #fff!important;
  background: #2b99ff!important;
}
.el-header .el-submenu:focus {
  outline: none;
}
.el-menu-item, .el-submenu__title{
  height:35px!important;
  line-height: 35px!important;
}
</style>